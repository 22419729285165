import ExternalLink from '@/components/shared/ExternalLink';
import Icon from '@/components/shared/icon/Icon';

import React from 'react';

export default class OrcidLink extends React.PureComponent {
  render() {
    const orcidUrl = formatOrcidId(this.props.orcidId);
    return (
      <ExternalLink className="orcid-link" href={`${orcidUrl}`}>
        <Icon icon="orcid-id" height="14" width="14" />
        <span className="orcid-link__id-label">{this.props.orcidId}</span>
      </ExternalLink>
    );
  }
}

export function formatOrcidId(id) {
  const formattedId = id;
  return `https://orcid.org/${formattedId}`;
}
