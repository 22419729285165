export const URL_REGEX = /https?:\/\/[\S]+[\w+$]/;

export function mkAbsoluteLink(url: string): string {
  if (url.search(/^http[s]?:\/\//) == -1) {
    return 'http://' + url;
  } else {
    return url;
  }
}

export function encodeParameter(parameter: string | number | boolean): string {
  return encodeURIComponent(parameter).replace(/'/g, '%27').replace(/-/g, '%2D');
}
