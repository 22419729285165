import AuthorProfilePicture from './AuthorProfilePicture';
import OrcidLink from './OrcidLink';

import * as util from '@/util';
import { getString } from '@/content/i18n';
import {
  heapAuthorClaimButton,
  heapAuthorEditButton,
  heapAuthorPendingButton,
} from '@/analytics/attributes/authorPage';
import { LOGIN_LOCATION } from '@/constants/LoginMethods';
import { mkAbsoluteLink } from '@/utils/url-format-util';
import { ModalId } from '@/constants/Modal';
import { QUERY_TYPE } from '@/constants/Alert';
import { showModal } from '@/actions/ModalActionCreators';
import AlertButton from '@/components/shared/alerts/AlertButton';
import AuthorStore from '@/stores/author/AuthorStore';
import AuthStore from '@/stores/AuthStore';
import CLIconButton, { SHAPE, SIZE, TYPE } from '@/components/library/button/CLIconButton';
import CLPopover, { ARROW_POSITION } from '@/components/library/popover/CLPopover';
import DropdownMenu from '@/components/desktop/common/dropdown/DropdownMenu';
import EnvInfo from '@/env/EnvInfo';
import ExternalLink from '@/components/shared/ExternalLink';
import Feature from '@/weblab/Feature';
import FeatureEnabled from '@/components/util/features/FeatureEnabled';
import FeatureGate from '@/components/util/features/FeatureGate';
import Icon from '@/components/shared/icon/Icon';
import IconButton from '@/components/shared/common/button/IconButton';
import IsDesktop from '@/components/util/env/IsDesktop';
import IsMobile from '@/components/util/env/IsMobile';
import Link from '@/router/Link';
import ModerationStatus, { ModerationStatusValue } from '@/constants/ModerationStatus';
import S2Dispatcher from '@/utils/S2Dispatcher';
import Shimmer from '@/components/shared/loading/Shimmer';
import TextTruncator from '@/components/shared/common/TextTruncator';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import type { AuthorDetailsRecord } from '@/models/author/AuthorDetails';
import type { Nullable, ReactNodeish } from '@/utils/types';

type AuthorLinkParams = {
  authorId: string;
  slug: string;
};

type Props = {
  authorTagName: string;
  showAHPExplanation?: Nullable<boolean>;
  showAlertsMenu?: Nullable<boolean>;
  showOrcid?: Nullable<boolean>;
  showHomepageLink?: Nullable<boolean>;
  showVerifiedCheckmark?: Nullable<boolean>;
};

type StateFromAuthStore = {
  claimedAuthorId: Nullable<string>;
  claimModerationStatus: Nullable<ModerationStatusValue>;
};

type StateFromAuthorStore = {
  authorDetail: Nullable<AuthorDetailsRecord>;
  isAuthorLoading: boolean;
};

type StateFromWeblabStore = {
  isAuthorProfilePictureEnabled: boolean;
};

type State = {
  isClaimPopoverVisible: boolean;
  isAlreadyClaimedPopoverVisible: boolean;
} & StateFromAuthStore &
  StateFromAuthorStore &
  StateFromWeblabStore;

export default class AuthorDetailCard extends React.PureComponent<Props, State> {
  static contextTypes = {
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    authorStore: PropTypes.instanceOf(AuthorStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  declare context: {
    authStore: AuthStore;
    authorStore: AuthorStore;
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    weblabStore: WeblabStore;
  };

  static defaultProps = {
    authorTagName: 'h1',
    showAHPExplanation: false,
    showAlertsMenu: true,
    showOrcid: true,
    showHomepageLink: true,
    showVerifiedCheckmark: true,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      ...this.getStateFromAuthStore(),
      ...this.getStateFromAuthorStore(),
      ...this.getStateFromWeblabStore(),
      isClaimPopoverVisible: false,
      isAlreadyClaimedPopoverVisible: false,
    };

    this.context.authorStore.registerComponent(this, () => {
      this.setState(this.getStateFromAuthorStore());
    });

    this.context.authStore.registerComponent(this, () => {
      this.setState(this.getStateFromAuthStore());
    });

    this.context.weblabStore.registerComponent(this, () => {
      this.setState(this.getStateFromWeblabStore());
    });
  }

  getStateFromAuthStore(): StateFromAuthStore {
    const { authStore } = this.context;
    return {
      claimedAuthorId: authStore.getClaimedAuthorId()?.toString() || null,
      claimModerationStatus: authStore.getAuthorClaimModerationStatus(),
    };
  }

  getStateFromAuthorStore(): StateFromAuthorStore {
    const { authorStore } = this.context;
    return {
      authorDetail: authorStore.getAuthorDetails(),
      isAuthorLoading: authorStore.isLoading(),
    };
  }

  getStateFromWeblabStore(): StateFromWeblabStore {
    const { weblabStore } = this.context;

    return {
      isAuthorProfilePictureEnabled: weblabStore.isFeatureEnabled(Feature.AuthorProfilePicture),
    };
  }

  openAuthorAlertsModal = (): void => {
    const { dispatcher } = this.context;

    const author = this.state.authorDetail?.author;
    if (author) {
      dispatcher.dispatch(
        showModal({
          id: ModalId.AUTHOR_ALERTS_MODAL,
          data: { author },
        })
      );
    }
  };

  renderAlertsMenu(): ReactNodeish {
    const author = this.state.authorDetail?.author;
    if (!author) {
      return null;
    }

    return (
      <CLPopover arrow={ARROW_POSITION.SIDE_LEFT_POS_MIDDLE} widthPx={290}>
        <div
          className="author-detail-card__alert-buttons-list"
          data-test-id="manage-author-alerts-list"
          ref="dropdown">
          <p className="author-detail-card__alert-header">
            {getString(_ => _.author.alerts.header)}
          </p>
          <ul>
            <li className="alert-button">
              <AlertButton
                className="author-detail_card__alert-toggle"
                queryType={QUERY_TYPE.AUTHOR_PAPER}
                queryValue={author.id}
                displayValue={author.name}
                subLocation={LOGIN_LOCATION.authorPapers}
                toggleLabel={getString(_ => _.author.alerts.papers)}
                isToggleButton
              />
            </li>
            <li className="alert-button">
              <AlertButton
                className="author-detail_card__alert-toggle"
                queryType={QUERY_TYPE.AUTHOR_CITATION}
                queryValue={author.id}
                displayValue={author.name}
                subLocation={LOGIN_LOCATION.authorCitations}
                toggleLabel={getString(_ => _.author.alerts.citations)}
                isToggleButton
              />
            </li>
          </ul>
        </div>
      </CLPopover>
    );
  }

  showClaimPopover = (): void => {
    this.setState({
      isClaimPopoverVisible: true,
    });
  };

  hideClaimPopover = (): void => {
    this.setState({
      isClaimPopoverVisible: false,
    });
  };

  toggleAlreadyClaimedPopover = (): void => {
    const { isAlreadyClaimedPopoverVisible } = this.state;

    this.setState({
      isAlreadyClaimedPopoverVisible: !isAlreadyClaimedPopoverVisible,
    });
  };

  renderShowClaim(authorId: string, linkParams: AuthorLinkParams): ReactNodeish {
    const { isClaimPopoverVisible } = this.state;
    const {
      envInfo: { isMobile },
    } = this.context;

    return (
      <div
        className="author-detail-card__claim-button"
        onMouseEnter={this.showClaimPopover}
        onMouseLeave={this.hideClaimPopover}
        onFocus={this.showClaimPopover}
        onBlur={this.hideClaimPopover}
        data-test-id="author-detail-card-claim-button">
        <Link
          {...heapAuthorClaimButton({ 'author-id': authorId })}
          to="AUTHOR_CLAIM"
          params={linkParams}
          className="icon-button button--secondary"
          aria-describedby="author-claim-button-voiceover"
          data-test-id="author-claim-button">
          <div className="flex-row-centered">
            <Icon icon="user" width="15" height="15" />
            <span className="icon-button-text">
              {getString(_ => _.author.buttonLabel.claimProfile)}
            </span>
          </div>
        </Link>
        <IsMobile>
          <button
            aria-label={getString(_ => _.author.buttonLabel.claimInfoAriaLabel)}
            className="author-detail-card__claim-info-button"
            data-test-id="author-detail-card-claim-info-button">
            <Icon icon="information" width="12" height="12" />
          </button>
        </IsMobile>
        {isClaimPopoverVisible && (
          <CLPopover
            className={classnames('author-detail-card__claim-popover', {
              'author-detail-card__claim-popover__mobile': isMobile,
            })}
            id="author-claim-button-voiceover"
            role="tooltip"
            arrow={isMobile ? ARROW_POSITION.SIDE_TOP_POS_RIGHT : ARROW_POSITION.SIDE_LEFT_POS_TOP}>
            {getString(_ => _.author.card.claimPopoverText)}
          </CLPopover>
        )}
      </div>
    );
  }

  renderClaimedExplanation(): ReactNodeish {
    const { isAlreadyClaimedPopoverVisible } = this.state;
    const isMobile = this.context.envInfo.isMobile;
    const ariaProps = {
      'aria-label': getString(_ => _.author.card.verifiedButtonAriaLabel),
      'aria-expanded': isAlreadyClaimedPopoverVisible,
    };

    return (
      <div
        className="author-detail-card__already-claimed"
        onMouseEnter={this.toggleAlreadyClaimedPopover}
        onMouseLeave={this.toggleAlreadyClaimedPopover}>
        <CLIconButton
          ariaProps={ariaProps}
          onClick={this.toggleAlreadyClaimedPopover}
          className="author-detail-card__already-claimed-button"
          aria-describedby="author-detail-card__already-claimed-popover-description-voiceover"
          icon={<Icon icon="checkmark" width="12" height="12" />}
        />

        {isAlreadyClaimedPopoverVisible && (
          <CLPopover
            className="author-detail-card__already-claimed-popover"
            onBlur={this.toggleAlreadyClaimedPopover}
            role="tooltip"
            arrow={isMobile ? ARROW_POSITION.SIDE_TOP_POS_RIGHT : ARROW_POSITION.SIDE_TOP_POS_LEFT}>
            <h3 className="author-detail-card__already-claimed-popover-header">
              {getString(_ => _.author.card.alreadyClaimedPopoverHeader)}
            </h3>
            <p
              className="author-detail-card__already-claimed-popover-description"
              id="author-detail-card__already-claimed-popover-description-voiceover">
              {getString(_ => _.author.card.alreadyClaimedPopoverDesc)}
            </p>

            <Link to="FAQ_ROOT" hash="claim-author-instructions">
              <React.Fragment>
                {getString(_ => _.author.card.alreadyClaimedPopoverLink)}
                <Icon
                  className="author-detail-card__already-claimed-popover-link-arrow"
                  icon="arrow-lite"
                  width="16"
                  height="10"
                />
              </React.Fragment>
            </Link>
          </CLPopover>
        )}
      </div>
    );
  }

  renderClaimPendingButton(authorId: string, linkParams: AuthorLinkParams): ReactNodeish {
    return (
      <Link
        {...heapAuthorPendingButton({ 'author-id': authorId })}
        to="ACCOUNT_AUTHOR_CLAIM_PENDING"
        params={linkParams}
        className="icon-button button--secondary flex-row-centered author-detail-card__claim-button-container"
        data-test-id="author-claim-button">
        <div className="flex-row-centered">
          <Icon icon="status-waiting" width="15" height="15" />
          <span className="icon-button-text">
            {getString(_ => _.author.buttonLabel.pendingStatus)}
          </span>
        </div>
      </Link>
    );
  }

  renderClaimPendingBanner(): ReactNodeish {
    return (
      <div className="author-claim__pending">
        <Icon icon="status-waiting" />
        <div className="author-claim__pending-label">
          {getString(_ => _.author.pendingBannerLabel)}
        </div>
      </div>
    );
  }

  renderEditAuthor(authorId: string, linkParams: AuthorLinkParams): ReactNodeish {
    return (
      <Link
        {...heapAuthorEditButton({ 'author-id': authorId })}
        to="ACCOUNT_AUTHOR_CONTACT"
        params={linkParams}
        className="icon-button button--secondary flex-row-centered author-detail-card__claim-button-container"
        data-test-id="author-claim-button">
        <div className="flex-row-centered">
          <Icon icon="user-with-pencil" width="15" height="15" />
          <span className="icon-button-text">
            {getString(_ => _.author.buttonLabel.editProfile)}
          </span>
        </div>
      </Link>
    );
  }

  renderLoadingCard(): ReactNodeish {
    const { showAHPExplanation, authorTagName = 'h1' } = this.props;

    const authorName = React.createElement(
      authorTagName,
      {
        'data-selenium-selector': 'author-name',
        className: 'author-detail-card__author-name',
      },
      <Shimmer cssWidth="200px" />
    );

    return (
      <div className="author-detail-card author-detail-card--is-loading">
        <div className="author-detail-card__main-content">
          <div className="author-detail-card__meta-section__meta-details">
            <div className="flex-row">{authorName}</div>
          </div>
          <div className="author-detail-card__meta-section__loading">
            <div className="author-detail-card__loading__row">
              <Shimmer className="author-detail-card__loading__label" cssWidth="76px" />
              <Shimmer className="author-detail-card__loading__stat" cssWidth="36px" />
            </div>
            <div className="author-detail-card__loading__row">
              <Shimmer className="author-detail-card__loading__label" cssWidth="48px" />
              <Shimmer className="author-detail-card__loading__stat" cssWidth="36px" />
            </div>
            <div className="author-detail-card__loading__row">
              <Shimmer className="author-detail-card__loading__label" cssWidth="54px" />
              <Shimmer className="author-detail-card__loading__stat" cssWidth="36px" />
            </div>
            <div className="author-detail-card__loading__row">
              <Shimmer className="author-detail-card__loading__label" cssWidth="162px" />
              <Shimmer className="author-detail-card__loading__stat" cssWidth="36px" />
            </div>
            <div className="author-detail-card__loading__actions">
              <Shimmer cssHeight="29px" />
              <Shimmer cssHeight="29px" />
            </div>
          </div>
        </div>
        {showAHPExplanation && (
          <div className="author-detail-card__ahp-explanation">
            {getString(_ => _.author.card.ahpExplanationText)}
          </div>
        )}
      </div>
    );
  }

  renderLoadedCard(): ReactNodeish {
    const {
      showAHPExplanation,
      showAlertsMenu,
      showOrcid,
      showHomepageLink,
      showVerifiedCheckmark,
      authorTagName,
    } = this.props;
    const { claimedAuthorId, claimModerationStatus, authorDetail, isAuthorProfilePictureEnabled } =
      this.state;

    const author = authorDetail?.author;
    const authorId = author?.id;
    if (!authorDetail || !author || !authorId) {
      return null;
    }

    const { totalPublications, isClaimedByAuthor, isClaimedBySignedInUser, isVerified, pronouns } =
      authorDetail;
    const { name, affiliations, orcidId, homepage, statistics } = author;
    const slug = author.slug || '';

    const isMobile = this.context.envInfo.isMobile;
    const linkParams: AuthorLinkParams = { slug, authorId };
    const showClaim = !isClaimedByAuthor && !claimedAuthorId;
    const showEdit = isClaimedBySignedInUser && claimModerationStatus === ModerationStatus.Approved;
    const showPending =
      isClaimedBySignedInUser &&
      (claimModerationStatus === ModerationStatus.Pending ||
        claimModerationStatus === ModerationStatus.PendingEmailVerification);
    const affiliationValue = affiliations ? affiliations.join(', ') : '';

    const authorLink = React.createElement(
      authorTagName,
      {
        'data-test-id': 'author-name',
        className: 'author-detail-card__author-name notranslate',
      },
      !!authorId && !!slug ? (
        <Link to="AUTHOR_PROFILE" params={{ authorId, slug }}>
          <React.Fragment>{name}</React.Fragment>
        </Link>
      ) : (
        name
      ),
      !!pronouns && <div className="author-detail-card__pronouns">{pronouns}</div>
    );

    return (
      <div
        className={classnames('author-detail-card', {
          'author-detail-card--show-profile-picture': isAuthorProfilePictureEnabled,
        })}>
        <FeatureGate feature={_ => _.AuthorProfilePicture}>
          <FeatureEnabled>
            <div className="author-detail-card__profile-picture">
              <AuthorProfilePicture author={author} sizePx={60} />
            </div>
          </FeatureEnabled>
        </FeatureGate>
        <div className="author-detail-card__main-content">
          <div className="author-detail-card__meta-section__meta-details">
            {isVerified && showVerifiedCheckmark && (
              <IsDesktop>
                <div className="author-detail-card__verified-check">
                  {this.renderClaimedExplanation()}
                </div>
              </IsDesktop>
            )}
            <div className="flex-row">
              {authorLink}
              {isVerified && showVerifiedCheckmark && (
                <IsMobile>
                  <div className="author-detail-card__verified-check-mobile">
                    {this.renderClaimedExplanation()}
                  </div>
                </IsMobile>
              )}
            </div>
            {isVerified && affiliationValue && (
              <div className="author-detail-card__affiliation">{affiliationValue}</div>
            )}
          </div>
          {(showOrcid || showHomepageLink) && isVerified && (orcidId || homepage) && (
            <div className="author-detail-card__meta-section__profile-details">
              {orcidId && (
                <div className="author-detail-card__meta-row author-detail-card__orcid-id">
                  <OrcidLink orcidId={orcidId} />
                </div>
              )}
              {homepage && (
                <div className="author-detail-card__meta-row author-detail-card__homepage">
                  <ExternalLink
                    className="author-detail-card__homepage__link"
                    href={mkAbsoluteLink(homepage)}>
                    <span className="author-detail-card__homepage__link__icon">
                      <Icon icon="fa-link" />
                    </span>
                    <span className="author-detail-card__homepage__label">{homepage}</span>
                  </ExternalLink>
                </div>
              )}
            </div>
          )}
          {isVerified && authorLink && affiliationValue && (
            <div className="author-detail-card__meta-section__seperator" />
          )}
          <div
            className={classnames('author-detail-card__meta-section__author-stats', {
              'author-detail-card__meta-section__author-stats-mobile': isMobile,
            })}>
            {!!totalPublications && (
              <div className="author-detail-card__stats-row">
                <span className="author-detail-card__stats-row__label">
                  {getString(_ => _.author.card.publications)}
                </span>
                <span className="author-detail-card__stats-row__value">
                  {util.format(totalPublications)}
                </span>
              </div>
            )}
            <div className="author-detail-card__stats-row">
              <Link className="author-detail-card__stats-row__label " to="FAQ_ROOT" hash="h-index">
                <> {getString(_ => _.author.card.hIndex)} </>
              </Link>
              <span className="author-detail-card__stats-row__value">
                {util.format(statistics.hIndex || 0)}
              </span>
            </div>
            <div className="author-detail-card__stats-row">
              <span className="author-detail-card__stats-row__label">
                {getString(_ => _.author.card.citations)}
              </span>
              <span className="author-detail-card__stats-row__value">
                {util.format(statistics.totalCitationCount || 0)}
              </span>
            </div>
            <div className="author-detail-card__stats-row">
              <span className="author-detail-card__stats-row__label">
                {getString(_ => _.author.card.influentialCitations)}
              </span>
              <span className="author-detail-card__stats-row__value">
                {util.format(statistics.totalInfluentialCitationCount || 0)}
              </span>
            </div>
          </div>
          <div className="author-detail-card__meta-section__demarker__bottom" />

          <IsDesktop>
            <div className="author-detail-card__meta-section__meta-buttons">
              {showAlertsMenu && (
                <DropdownMenu
                  ariaLabel={getString(_ => _.author.buttonLabel.followAuthor)}
                  className="author-detail-card__alert-dropdown"
                  content={this.renderAlertsMenu()}>
                  <IconButton
                    tagName="span"
                    className="button--primary"
                    iconProps={{ icon: 'fa-bell', width: '12', height: '12' }}
                    testId="manage-author-alerts-button"
                    text={getString(_ => _.author.buttonLabel.followAuthor)}
                  />
                </DropdownMenu>
              )}
              {showClaim && this.renderShowClaim(authorId, linkParams)}
              {showPending && this.renderClaimPendingButton(authorId, linkParams)}
              {showEdit && this.renderEditAuthor(authorId, linkParams)}
            </div>
          </IsDesktop>
          {/* hide mobile claim and claim pending behind mobile sign in feature flag */}
          {(showClaim || showPending) && (
            <IsMobile>
              <div className="author-detail-card__meta-section__meta-buttons">
                {showClaim && this.renderShowClaim(authorId, linkParams)}
                {showPending && this.renderClaimPendingBanner()}
              </div>
            </IsMobile>
          )}

          <IsMobile>
            <div className="author-detail-card__action-buttons">
              <CLIconButton
                aria-label={getString(_ => _.author.card.alertsAriaLabel)}
                type={TYPE.PRIMARY}
                icon={<Icon icon="fa-bell" height="12" width="16" />}
                shape={SHAPE.CIRCLE}
                size={SIZE.LARGE}
                onClick={this.openAuthorAlertsModal}
              />
            </div>
          </IsMobile>
        </div>
        {showAHPExplanation && (
          <div className="author-detail-card__ahp-explanation">
            <TextTruncator
              text={getString(_ => _.author.card.ahpExplanationText)}
              limit={70}
              truncateByDefault={true}
              extendActionText={getString(_ => _.author.card.ahpExplanationShowMore)}
              extendAriaLabel={getString(_ => _.author.card.ahpExplanationShowMore)}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { isAuthorLoading, authorDetail } = this.state;
    if (isAuthorLoading || !authorDetail) {
      return this.renderLoadingCard();
    }
    return this.renderLoadedCard();
  }
}
