import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapAuthorClaimButton = (props: { 'author-id': string }) =>
  mkHeapAttributes({
    id: 'author_claim_button',
    ...props,
  });

export const heapAuthorEditButton = (props: { 'author-id': string }) =>
  mkHeapAttributes({
    id: 'author_edit_button',
    ...props,
  });

export const heapAuthorListItem = (props: { 'author-id': string }) =>
  mkHeapAttributes({
    id: 'author_list_item',
    ...props,
  });

export const heapAuthorPendingButton = (props: { 'author-id': string }) =>
  mkHeapAttributes({
    id: 'author_pending_button',
    ...props,
  });

export const heapAuthorPublicationsTab = () =>
  mkHeapAttributes({
    id: 'author_publications_tab',
  });

export const heapAuthorCitingAuthorsTab = () =>
  mkHeapAttributes({
    id: 'author_citing_authors_tab',
  });

export const heapAuthorReferencedAuthorsTab = () =>
  mkHeapAttributes({
    id: 'author_referenced_authors_tab',
  });

export const heapAuthorCoAuthorsTab = () =>
  mkHeapAttributes({
    id: 'author_coauthors_tab',
  });

export const heapAuthorRecommendationsCoAuthorsButton = () =>
  mkHeapAttributes({
    id: 'author_recommendations_coauthors_button',
  });
