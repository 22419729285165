import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { Nullable, ReactNodeish } from '@/utils/types';
import wrap from '@/utils/wrap';

import classnames from 'classnames';
import React from 'react';

type DropdownMenuProps = {
  ariaLabel?: string;
  children?: Nullable<ReactNodeish>;
  content: ReactNodeish;
  className?: Nullable<string>;
  onShow?: Nullable<() => void>;
  onHide?: Nullable<() => void>;
  showMenu?: Nullable<boolean>;
  testId?: Nullable<string>;
};

type State = {
  showMenu: boolean;
};

export default class DropdownMenu extends React.PureComponent<DropdownMenuProps, State> {
  constructor(...args: [any]) {
    super(...args);
    this.state = { showMenu: !!this.props.showMenu };
  }

  onClick = (): void => {
    if (!this.state.showMenu) {
      this.setState({ showMenu: !this.state.showMenu });
    }
  };

  _handleBlurDropdownMenu = (clickEvent: Event): void => {
    if (this.state.showMenu) {
      let currentTarget = clickEvent.target;
      let isMenuChild = false;

      while (
        currentTarget &&
        currentTarget instanceof Element &&
        currentTarget !== document.body &&
        !isMenuChild
      ) {
        isMenuChild = currentTarget === this.refs.dropdown;
        if (!isMenuChild) {
          currentTarget = currentTarget.parentElement;
        }
      }

      if (!isMenuChild) {
        this.setState({ showMenu: false });
      }
    }
  };

  _onClickKeyDownProps = mkOnClickKeyDown<HTMLDivElement>({
    onClick: this.onClick,
  });

  // TODO(codeviking): This is a bit of an anti-pattern and should likely be consolidated into
  // a store.  I'm doing this this way given the immediacy of this feature
  componentWillReceiveProps(nextProps): void {
    if (nextProps.showMenu === false) {
      this.setState({ showMenu: false });
    } else if (nextProps.showMenu === true) {
      this.setState({ showMenu: false });
    }
  }

  componentDidMount(): void {
    document.body.addEventListener('click', this._handleBlurDropdownMenu);
    document.body.addEventListener('keyup', this._handleBlurDropdownMenu);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener('click', this._handleBlurDropdownMenu);
    document.body.removeEventListener('keyup', this._handleBlurDropdownMenu);
  }

  componentDidUpdate(prevProps: DropdownMenuProps, prevState: State): void {
    if (prevState.showMenu !== this.state.showMenu) {
      if (this.state.showMenu && this.props.onShow) {
        this.props.onShow();
      } else if (this.props.onHide) {
        this.props.onHide();
      }
    }
  }

  render(): ReactNodeish {
    const { children, content, className, testId } = this.props;
    const { showMenu } = this.state;

    const trigger = children
      ? React.cloneElement(children, {
          onClick: wrap(children.props.onClick, () => this.setState({ showMenu: !showMenu })),
          className: classnames(children.props.className, 'dropdown-menu-trigger'),
        })
      : null;

    const wrappedContent = showMenu ? <div className="dropdown-menu-content">{content}</div> : null;

    const classNames = classnames('dropdown-menu', { 'dropdown-is-visible': showMenu }, className);

    return (
      <div
        className={classNames}
        ref="dropdown"
        tabIndex={0}
        role="button"
        aria-label={this.props.ariaLabel}
        aria-expanded={this.state.showMenu}
        {...this._onClickKeyDownProps}
        {...(testId ? { 'data-test-id': testId } : {})}>
        {trigger}
        {wrappedContent}
      </div>
    );
  }
}
